import { useContext } from 'react'
import slugify from 'slugify'
import { TagCommanderContext } from '../contexts/tagcommander'

const MENU_TMS_HIT_BASE = 'menu-burger'
const HEADER_TMS_HIT_BASE = 'header'

export function useTMSClickHit(base?: string): { hit: (...titles: string[]) => void } {
  const { hit: tmsHit } = useContext(TagCommanderContext)

  const hit = (...titles: string[]): void => {
    const titlesWithBase = base ? [base, ...titles] : titles
    const slugifiedTitles = titlesWithBase
      .map((title) => slugify(title, { lower: true, strict: true }))
      .join('_')
    tmsHit(
      {
        screen_clickableElementName: slugifiedTitles,
      },
      { isClickEvent: true },
    )
  }

  return { hit }
}

export function useTMSHitForMenuBurger(): { menuHit: (...titles: string[]) => void } {
  const { hit: menuHit } = useTMSClickHit(MENU_TMS_HIT_BASE)
  return { menuHit }
}

export function useTMSHitForHeader(): { headerHit: (...titles: string[]) => void } {
  const { hit: headerHit } = useTMSClickHit(HEADER_TMS_HIT_BASE)
  return { headerHit }
}
