import type { ReactNode, Context } from 'react'
import { createContext } from 'react'

export interface ITimeManager {
  prefix?: string
  formatType?: 'DATE' | 'TIME' | 'DATE_TIME'
}

export type ITimeManagerContext = Context<ITimeManager>

export const TimeManagerContext: ITimeManagerContext = createContext({
  prefix: 'Publié ',
  formatType: 'DATE_TIME',
})

export function TimeManager({ children }: { children?: ReactNode }): JSX.Element {
  return (
    <TimeManagerContext.Provider value={{ prefix: null, formatType: 'DATE' }}>
      {children}
    </TimeManagerContext.Provider>
  )
}
