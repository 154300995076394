import { SVGIcon } from '../SVGIcon'

export interface IIcon {
  name: string
  primaryColor?: string
  secondaryColor?: string
  size?: string
  width?: string
  height?: string
  className?: string
  onClick?: () => void
  testId?: string
  orientation?: 'up' | 'right' | 'down' | 'left'
  backgroundColor?: string
  disabled?: boolean
}

export function Icon({
  name,
  primaryColor,
  secondaryColor,
  size = '24px',
  width,
  height,
  className,
  onClick,
  testId,
  orientation,
  backgroundColor,
  disabled,
}: IIcon) {
  const svgClassName = `Icon ${className || ''}`.trim()

  const RenderComponent = onClick ? 'button' : 'div'
  const containerProps = onClick ? { onClick, disabled: disabled } : {}

  return (
    <>
      <RenderComponent
        {...containerProps}
        className={`${svgClassName} flex justify-center items-center`}
        data-testid={testId}
      >
        <SVGIcon
          name={name}
          width={width}
          height={height}
          size={size}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          orientation={orientation}
        />
      </RenderComponent>

      <style jsx>{`
        .Icon {
          background-color: ${backgroundColor ?? 'none'};
          padding: ${backgroundColor ? '10px' : 'none'};
          border-radius: ${backgroundColor ? '40px' : 'none'};
          width: ${width || size};
          height: ${height || size};
        }

        button {
          cursor: pointer;
          background: transparent;
          border: none;
          padding: 0;
        }
        
        button:disabled {
          opacity: 0.5;
        }

        div,
        button {
          width: ${width || size};
          height: ${height || size};
        }
        
        button:disabled {
          cursor: initial;
        }
      `}</style>
    </>
  )
}
